<template>
  <div class="home">
    
    <principal-navbar />

    <!-- DESKTOP -->
    <div class="home-image">
      <div class="home-back py-5">

        <div class="container-fluid py-5" data-aos="fade-down" data-aos-duration="2000">
          <div class="row min-vh-100">
          
            <div class="col-12">

                <div class="row d-flex justify-content-center flex-grow-1">
                  <div class="col-sm-8 content my-auto text-center pt-5">
                    <img src="../assets/blanco.png" class="img-fluid mb-5" alt="Aceros Prolider" style="height: 250px;">
                    <p class="text-white h5 mb-3">
                      <b-button href="tel:3319719905" variant="transparent" class="text-white">
                        <span class="pr-3 "><i class="fal fa-phone"></i></span>
                        33 1971 9905
                      </b-button>
                      <b-button href="tel:3319719906" variant="transparent" class="text-white">
                        <span class="pr-3"><i class="fal fa-phone"></i></span>
                        33 1971 9906
                      </b-button>
                      <b-button href="https://api.whatsapp.com/send?phone=523315185727" variant="transparent" class="text-white">
                        <span class="px-3"><i class="fab fa-whatsapp"></i></span>
                        33 1518 5727
                      </b-button>
                    </p>
                    <p class="text-white h5">
                      <b-button href="mailto:contacto@aprolider.com" variant="transparent" class="text-white">
                        <span class="pr-3"><i class="fas fa-envelope"></i></span>
                        contacto@aprolider.com
                      </b-button>
                    </p>
                  </div>
                </div>

            </div>

          </div>
        </div>

      </div>
    </div>

    <!-- ABOUT US -->
    <principal-aboutus type="false" />
    
    <!-- PRODUCTS -->
    <principal-products />

    <!-- PRICE -->
    <principal-price />

    <!-- CONTACT -->
    <principal-contact />

    <!-- FOOTER -->
    <principal-footer />

  </div>
</template>

<script>
export default {
  name: 'Home',
  metaInfo() {
    return { 
      title: "Aceros Prolider SA de CV",
      meta: [
        { name: 'description', content:  'Somos una empresa orgullosamente Mexicana, con un equipo de colaboradores altamente capacitado, comprometido y con un gran gusto por el servicio a nuestros clientes.'},
        { property: 'og:title', content: "Aceros Prolider SA de CV"},
        { property: 'og:site_name', content: 'Aceros Prolider SA de CV'},
        { property: 'og:type', content: 'website'},
      ]
    }
  },
  components: {
    PrincipalNavbar: () => import('@/components/PrincipalNavbar.vue'),
    PrincipalProducts: () => import('@/components/PrincipalProducts.vue'),

    PrincipalAboutus: () => import('@/components/PrincipalAboutus.vue'),
    PrincipalPrice: () => import('@/components/PrincipalPrice.vue'),
    PrincipalContact: () => import('@/components/PrincipalContact.vue'),
    PrincipalFooter: () => import('@/components/PrincipalFooter.vue'),
  }
}
</script>

<style scoped>
.home-image {
  background-image: url('../assets/inicio.jpg');
}

.home-back {
  background-color: black;
  opacity: .8;
}
</style>