import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  { path: '/', component: Home },
  { path: '/nosotros', component: () => import('@/views/Nosotros.vue') },
  { path: '/productos', component: () => import('@/views/Products.vue') },
  { path: '/cotizacion', component: () => import('@/views/Cotizacion.vue') },
  { path: '/contacto', component: () => import('@/views/Contacto.vue') },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
